export default {
  data () {
    return {
      customMembership: {
        dashboard_name: 'Custom',
        selectedPrice: {}
      }
    }
  },
  computed: {
    computedPlan () {
      let plan = {}
      if (this.company && this.company.deals && this.company.deals.length > 1) { // if there is more than one deal
        return this.customMembership
      }
      if (this.getMainPlan(this.setDeal(this.company))) {
        plan = this.getMainPlan(this.setDeal(this.company))
      }
      const { planInPath, freePlan } = this.getFreeplanEvaluatePlanInPath(this.company, plan.id)
      if (!planInPath && plan && Object.keys(plan).length > 0) { // if the plan is not in path
        return this.customMembership
      }
      if (plan && Object.keys(plan).length > 0) { // a plan was found and is in path
        return plan
      }
      return freePlan
    }
  },
  methods: {
    // PLEASE NOTE THAT THIS WILL ONLY WORK FOR INHERITED BEHAVIOUR (AS OF TODAY THE ONLY SUPPORTED BEHAVIOUR)
    getMainPlan (deal) {
      let mainPlan
      let highestOrder
      if (deal && deal.frozen_plans) {
        deal.frozen_plans.forEach((frozenPlan) => {
          if (highestOrder === undefined || highestOrder < frozenPlan.pivot.order) {
            highestOrder = frozenPlan.pivot.order
            mainPlan = JSON.parse(frozenPlan.membership_plan)
            mainPlan.selectedPrice = mainPlan.prices.find((price) => price.id === frozenPlan.pivot.price_id_in_frozen_plan)
            mainPlan.orderInPath = frozenPlan.pivot.order
          } 
        })
      }
      return mainPlan || ''
    },
    getAllPlans (deal) {
      let allPlans = []
      
      deal.frozen_plans.forEach((frozenPlan) => {
        let plan = JSON.parse(frozenPlan.membership_plan)
        plan.selectedPrice = plan.prices.find((price) => price.id === frozenPlan.pivot.price_id_in_frozen_plan)
        allPlans.push(plan)
      })
      return allPlans
    },
    getAllNumericPeriodicities () {
      return [
        31536000,
        15768000,
        7776000,
        2592000
      ]
    },
    getHumanizedPeriodicity () {
      switch (this.allPlans[0].selectedPrice.periodicity) {
        case 31536000:
          return 'Annual'
        case 15768000:
          return 'Bi-Annual'
        case 7776000:
          return 'Quarterly'
        case 2592000:
          return 'Monthly'
        default:
          return `${this.allPlans[0].selectedPrice.periodicity / (60 * 60 * 24)} days`
      }
    },
    getPeriodicityFromNumber (periodicity) {
      switch (periodicity) {
        case 2592000:
          return 'Monthly'
        case 7776000:
          return 'Quarterly'
        case 15768000:
          return 'Bi-Annual'
        case 31536000:
          return 'Annual'
        default:
          return ''
      }
    },
    getNumberPeriodicityFromWord (periodicity) {
      switch (periodicity) {
        case 'Monthly':
          return 2592000
        case 'Quarterly':
          return 7776000
        case 'Bi-Annual':
          return 15768000
        case 'Annual':
          return 31536000
        default:
          return ''
      }
    },
    getPeriod (periodicity) {
      switch (periodicity) {
        case 'Monthly':
          return 'month'
        case 'Quarterly':
          return 'quarter'
        case 'Bi-Annual':
          return 'bi-annual'
        case 'Annual':
          return 'year'
        default:
          return ''
      }
    },
    _getBenefitPrice(benefit, period) {
      if (!benefit || !period) {
        return 0
      }
      if (!benefit.divide_by_periodicity) {
        return Number(benefit.quantity) * Number(benefit.price)
      }
      return Number(benefit.quantity) * Number(benefit.price) / Math.round(31536000 / period)
    },
    getAcumulatedPackageValue (indexPlan, allPlans, periodicity) {
      let i
      let period
      let priceFound
      let totalAmount = 0
      let amountPerPlan
      for (i = 0; i <= indexPlan; i += 1) {
        amountPerPlan = 0
        period = this.getNumberPeriodicityFromWord(periodicity);
        priceFound = allPlans[i].plan.prices.find((price) => price.periodicity == period)
        if (priceFound && priceFound.price === 0) {
          priceFound = undefined
        }
        if (priceFound && priceFound.benefits && priceFound.benefits.length > 0) {
          priceFound.benefits.forEach((benefit) => amountPerPlan += this._getBenefitPrice(benefit, period))
        }
        totalAmount += amountPerPlan
      }
      return totalAmount
    },
    getPeriodicitiesFromPath (path) {
      let periodicitiesInPlan = []
      let activateRadioButtons = false
      let periodicityWord
      if (path && path.plan_extensions) {
        path.plan_extensions.forEach((planExtension) => {
          if (planExtension.plan) {
            planExtension.plan.prices.forEach((price) => {
              periodicityWord = this.getPeriodicityFromNumber(price.periodicity)
              if (periodicityWord !== 'Annual' && periodicityWord !== 'Quarterly') {
                activateRadioButtons = true
                periodicitiesInPlan.push(periodicityWord)
              }
            })
          }
        })
      }
      if (periodicitiesInPlan === []) {
        return null
      }
      return periodicitiesInPlan
    },
    getFreeplanEvaluatePlanInPath (company, planId) {
      let planInPath = false
      let freePlan = {}
      if (company && company.path && company.path.plan_extensions) {
        company.path.plan_extensions.forEach((planExtension) => {
          if (planExtension.plan && planExtension.plan.prices) {
            if (planExtension.plan.id === planId) {
              planInPath = true
            }
            planExtension.plan.prices.forEach((price) => {
              if (price && price.price === '0.00') {
                freePlan = planExtension.plan
                freePlan.selectedPrice = price
              }
            })
          }
        })
      }
      return { planInPath, freePlan }
    }
  }
}
