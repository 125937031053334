export default {
  computed: {
    daysToExpire () {
      const diff = new Date(this.deal.expires_at).getTime() - new Date().getTime()
      const days = Math.round(diff / (1000 * 24 * 60 * 60))
      return days <= 0 ? 0 : days
    },
    expiresSoon () {
      if (this.daysToExpire <= 30) {
        return true
      } 
      return false
    },
    path () {
      let path = {}
      path.plan_extensions = this.deal.frozen_plans.map((frozen) => {
        let plan = JSON.parse(frozen.membership_plan)
        return {
          order: frozen.pivot.order,
          plan_id: plan.id,
          plan: plan
        }
      })
      return path
    }
  },
  methods: {
    getAllBenefitsUntilPlan (deal, plan, scaledRulesString) {
      let benefits = []
      let benefitsWithoutScaledRulesProccessed = []
      let benefitsScaledRulesProccessed = {}
      let benefitsWithPathOrder
      let planAux
      let priceIndex
      deal.frozen_plans.forEach((planIterator) => {
        if (plan && plan.orderInPath >= planIterator.pivot.order) { // Do not evaluate plans in higher order
          planAux = JSON.parse(planIterator.membership_plan)
          planAux.prices.forEach((price, index) => {
            if (price.id === planIterator.pivot.price_id_in_frozen_plan && price.benefits && price.benefits.length > 0) {
              benefitsWithPathOrder = price.benefits.map((benefit) => {
                benefit.orderInPath = planIterator.pivot.order
                return benefit
              })
              benefits = benefits.concat(benefitsWithPathOrder)
              if (plan && plan.id === planAux.id) {
                priceIndex = index
              }
            }
          })
        }
      })
      // lets filter the benefits with less order in the plan path if scale is true
      benefits.forEach((benefit) => {
        if (scaledRulesString && scaledRulesString.includes(benefit.rule)) {
          benefit.scaled_rule = true
          if (!benefitsScaledRulesProccessed[benefit.rule]) {
            benefitsScaledRulesProccessed[benefit.rule] = benefit
          } else {
            if (benefit.orderInPath > benefitsScaledRulesProccessed[benefit.rule].orderInPath) {
              benefitsScaledRulesProccessed[benefit.rule] = benefit
            }
          }
        } else {
          benefitsWithoutScaledRulesProccessed.push(benefit)
        }
      })
      return {
        benefits: benefitsWithoutScaledRulesProccessed.concat(Object.values(benefitsScaledRulesProccessed)),
        priceIndex
      }
    },
    setDeal (company) {
      let expiration = null
      let deal = {}
      if (company) {
        company.deals.forEach((dealIterator) => {
          dealIterator.expires_at = new Date(dealIterator.expires_at).getTime()
          if (!expiration) {
            expiration = dealIterator.expires_at
            deal = dealIterator
          } else {
            if (dealIterator.expires_at < expiration) {
              deal = dealIterator
            }
          }
        })
      }
      return deal
    }
  }
}
